
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAccountStore } from '@/store/account';
import { USER_TYPE_ENUM } from '@/constant/system-info';
import { useMenuStore } from '@/store/menu';

// interface IPropsType {
//   image: string;
//   title: string;
//   description: string;
//   hasGoback: boolean;
// }

export default defineComponent({
  name: 'Exception',
  props: {
    // 图片
    image: {
      type: String,
    },
    // 标题
    title: {
      type: String,
    },
    // 描述
    description: {
      type: String,
      default: '',
    },
    // 是否显示返回键
    hasGoback: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const accountStore = useAccountStore();
    const menuStore = useMenuStore();
    const handleLogout = async () => {
      await accountStore.logout();
      router.replace({
        name: 'Login',
      });
    };
    const handleBack = () => {
      router.go(-1);
    };

    const currentRoute = useRoute();
    const { goback } = currentRoute.query;
    const gobackShow = ref(goback === 'true');
    const handleToHome = () => {
      router.push({
        name: 'DomesticGoods',
      });
    };
    const hasHomeAuth = computed(() => menuStore.authMenuList.includes('DomesticGoods'));
    return {
      handleLogout,
      hasHomeAuth,
      USER_TYPE_ENUM,
      accountStore,
      gobackShow,
      handleBack,
      handleToHome,
    };
  },
});
